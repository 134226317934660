import React from "react"
import Layout from "../components/layout"
import "./contact.scss"
import { Seo } from "../components/seo"

const ContactPage = () => {
  return (
    <div>
      <Layout>
        <h1>Contact</h1>
        <p>
          Contact me at:
          <a
            className="links"
            href="mailto:abdurrehman135@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            abdurrehman135@gmail.com
          </a>
        </p>
        <p>
          You can connect with me on{" "}
          <a
            className="links"
            href="https://www.linkedin.com/in/muhammad-siddiqui"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </p>
      </Layout>
    </div>
  )
}

export default ContactPage

export const Head = () => {
  return <Seo title="Contact" />
}
